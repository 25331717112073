import { Controller } from "@hotwired/stimulus"
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

export default class extends Controller {

  static values = { displayMode: String, successMessage: String }
  static targets = ['trigger']

  selectInputValue(e){
    e.currentTarget.select();
  }

  // ClipboardJS needs pre-initialization so we cannot use stimulus actions
  // we could also we window@load->controller#loadCliploardJS
  initialize() {
    this.triggerTarget.addEventListener('click', (e) => { e.preventDefault() })

    let clipboard = new ClipboardJS(this.triggerTarget);
    clipboard.on('success', function(e){
      if(!e.trigger._tippy){
        tippy(e.trigger, {
          animation: 'scale',
          inertia: true,
          content: this.successMessageValue,
          trigger: 'manual'
        });
      }
      e.trigger._tippy.show();
      setTimeout(() => { e.trigger._tippy.hide() }, 2500)
    }.bind(this));

  }
}
