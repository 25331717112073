import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { options: Object }
  static targets = ['link', 'tab']

  connect() {
    this.selectedStyles = (this.optionsValue['selected_styles'] || "tabbed-content__link--selected").split(' ')
    this.unselectedStyles = (this.optionsValue['unselected_styles'] || "tabbed-content__link--unselected").split(' ')

    this._handleAnchor()
    this._hideUnactiveTabs()
  }

  _hideUnactiveTabs(){
    const currentAnchor = this._getCurrentAnchor()

    if(currentAnchor){
      this.tabTargets.map(target => {
        if(target.dataset.ref !== currentAnchor){
          target.classList.add('hidden')
        }
      })
    }
  }

  setAnchor(e){
    e.preventDefault();
    window.location.hash = e.target.dataset.ref
  }

  _handleAnchor(){
    window.addEventListener("hashchange", (e) => {
      this._setActiveTab(e.target.location.hash)
    }, false);
    this._setActiveTab(window.location.hash || this.linkTargets[0].dataset.ref)
  }

  _setActiveTab(ref){
    ref = ref.replace('#', '')
    if(ref){
      this.linkTargets.map(link => {
        if(link.dataset.ref === ref){

          this.selectedStyles.map(style => link.classList.add(style))
          this.unselectedStyles.map(style => link.classList.remove(style))
        } else {
          this.selectedStyles.map(style => link.classList.remove(style))
          this.unselectedStyles.map(style => link.classList.add(style))
        }
      })

      this.tabTargets.map(tab => {
        if(tab.dataset.ref === ref){
          this._showElement(tab)
        } else {
          this._hideElement(tab)
        }
      })
    }
  }

  _showElement(element){
    element.classList.remove('hidden')
  }

  _hideElement(element){
    element.classList.add('hidden')
  }

  _getCurrentAnchor(){
    return window.location.hash ? window.location.hash.substring(1) : this.linkTargets[0].dataset.ref
    // return window.location.hash ? window.location.hash.substring(1) : this.hasLinkTarget ? this.linkTargets[0].dataset.ref : null
  }
}
