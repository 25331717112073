// filters_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = { options: Object }
  static targets = ['field'];

  connect(){
    this.form = this.element
    if(this.optionsValue['hide_submit']){
      this.form.querySelector('[type="submit"]').classList.add('hidden')
    }
  }

  perform() {
    const url = `${window.location.pathname}?${this.params}`;
  }

  get params() {
    return this.fieldTargets.map((f) => (f.getAttribute('type') == 'checkbox' && f.checked) || f.getAttribute('type') != 'checkbox' ? `${f.name}=${f.value}` : null).filter((s) => s).join("&");
    // return this.fieldTargets.map((t) => `${t.name}=${t.value}`).join("&");
  }
}
