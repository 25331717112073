import 'core-js/stable'
import { Controller } from "@hotwired/stimulus"
import { isElementHidden } from '@javascript/javascripts/utils'

export default class extends Controller {

  initialize(){
    this.options = JSON.parse(this.element.dataset.controllerOptions)
    this.panes = [...document.querySelectorAll(this.options.panes)]
    this.links = [...document.querySelectorAll(this.options.links)]
    this.cssPrefix = 'c--tabs--indicator'
    this.decorateLinks()
    this.registerLinks()
  }

  decorateLinks(){
    if(this.options.indicator){
      this.links.map(link => {
        const target = document.querySelector(`[data-ref="${link.dataset.target}"]`);
        this._showMoreIndicator(link)
        this._setAttributesForARIA(link, target)
      });
    }
  }

  registerLinks(){

    this.links.map(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();


        const target = document.querySelector(`[data-ref="${link.dataset.target}"]`);
        this.panes.filter(pane => pane.dataset.ref !== link.dataset.target).map(pane => { this._hidePane(pane) })

        if(isElementHidden(target)){
          this._showPane(target)
        } else {
          this._hidePane(target)
        }

      })
    })
  }

  _hidePane(pane){
    const link = this.element.querySelector(`[data-target="${pane.dataset.ref}"]`)
    link.setAttribute('aria-expanded', false)
    pane.setAttribute('hidden', 'hidden')
    this._hideElement(pane)
    if(this.options.indicator){ this._showMoreIndicator(link) }
  }

  _showPane(pane){
    const link = this.element.querySelector(`[data-target="${pane.dataset.ref}"]`)
    link.setAttribute('aria-expanded', true)
    pane.removeAttribute('hidden')
    this._showElement(pane)
    if(this.options.indicator){ this._showLessIndicator(link) }
  }

  _setAttributesForARIA(link, target){
    link.setAttribute('aria-expanded', false)
    link.setAttribute('aria-controls', link.dataset.target)
    link.setAttribute('id', `link_${link.dataset.target}`)
    target.setAttribute('aria-labelledby', `link_${link.dataset.target}`)
    target.setAttribute('hidden', 'hidden')
    target.setAttribute('id', `link_${link.dataset.target}`)
  }

  _showMoreIndicator(element){
    element.classList.remove(`${this.cssPrefix}:indicator-less`);
    element.classList.add(`${this.cssPrefix}:indicator-more`);
  }

  _showLessIndicator(element){
    element.classList.remove(`${this.cssPrefix}:indicator-more`);
    element.classList.add(`${this.cssPrefix}:indicator-less`);
  }

  _showElement(element){
    element.classList.remove('hidden')
    element.removeAttribute('hidden')
    if(element.style.display === 'none'){
      element.style.display = ''
    }
  }

  _hideElement(element){
    element.classList.add('hidden')
    element.setAttribute('hidden', 'hidden')
  }

}
