import { Controller } from "@hotwired/stimulus"
import { deepMerge } from '@javascript/javascripts/utils'
import { tns } from 'tiny-slider/src/tiny-slider.js'
import './carousel_component.css'

export default class extends Controller {

  static get targets() {
    return []
  }

  initialize() {
    this.options = JSON.parse(this.data.get('options'))
    this._boot()
  }

  _boot(){
    if(!this.element.dataset.behaviorLoaded){
      this.element.classList.add('carousel--loading');
      const defaults = {
        container: this.element,
        touch: true,
        nav: false,
        nonce: 'oVyNsBBOgnKX4o3kiJDiAQ=='
      }
      this.carousel = tns(deepMerge(defaults, this.options))
      this.element.classList.remove('carousel--loading')
      this.element.dataset.behaviorLoaded = true
    }

    // if(!this.element.dataset.behaviorLoaded){
    //   import('tiny-slider/src/tiny-slider.js').then(module => {
    //     const defaults = {
    //       container: this.element,
    //       touch: true,
    //       nonce: 'oVyNsBBOgnKX4o3kiJDiAQ=='
    //     }
    //     const args = Object.assign(defaults, this.options)
    //     this.carousel = module.tns(args)
    //     this.element.classList.remove('carousel--loading')
    //     this.element.dataset.behaviorLoaded = true
    //   })
    // }

  }

}
