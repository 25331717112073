import 'core-js/stable'
import { Controller } from "@hotwired/stimulus"
import { jsRequestHeaders, isElementHidden, isTruthy, isFalsy } from '@javascript/javascripts/utils'
import axios from 'axios'
import qs from 'qs'

// import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['source']

  initialize(){
  }

  connect() {
    this.handleSessionBeat();
    this.handleTooltips();
    this.handleSessionClaim();
    this.handleMasonry();
    this.handleVisibility();
    this.handleBackendLabelsLocales();
  }

  onChangeSubmit(e){
    e.target.form.submit();
  }

  handleBackendLabelsLocales(){
    if(['admin', 'dashboard'].includes(this.data.get('realm'))){
      [...document.querySelectorAll('label')].map(label => label.dataset.locale ? (label.innerText = label.innerText + ' (' + label.dataset.locale + ')') : null)
    }
  }

  handleVisibility(){
    const supported = {
      events: ['click'],
      actions: ['toggle', 'hide', 'show']
    };

    [...document.querySelectorAll('[data-visibility]')].map(element => {
      const behaviors = element.dataset.visibility;
      const options = element.dataset.visibilityOptions ? JSON.parse(element.dataset.visibilityOptions) : {};

      [...behaviors.split('|')].map(behavior => {
        const [event, selector, action] = behavior.split('->')
        if(supported.events.includes(event) && supported.actions.includes(action)){

          element.addEventListener(event, (e) => {
            e.preventDefault();
            [...document.querySelectorAll(selector)].map(target => {
              if('toggle' === action){
                if(isElementHidden(target)){
                  this._show(target);
                } else {
                  this._hide(target);;
                }
              }
              if('hide' === action){
                this._hide(target);
              }
              if('show' === action){
                this._show(target);
              }
            })

          })
        }
      })
    })
  }

  handleTooltips(){
    const tooltips = Array.from(document.querySelectorAll('[data-tooltip]'))
    if(tooltips.length > 0){
      // import('tippy.js/dist/tippy.css')
      import('tippy.js').then(tippy => {
        tooltips.forEach((element, index) => {
          tippy.default(element, { content: element.getAttribute('aria-label') || element.getAttribute('data-tooltip') })
        })
      })
    }
  }

  handleSessionBeat(){

    const handleLogout = (e) => {
      if(Math.round(Date.now() / 1000) > Env['expires_at']){
        e.preventDefault();
        window.location = Env.routes.login_permalink
      }
    }

    if(['app'].includes(this.data.get('realm')) && Env?.['expires_at']){
      let loggedIn = true;
      if (!Date.now) {
        Date.now = function now() {
          return new Date().getTime();
        };
      }
      ['click', 'mousemove', 'tap', 'keydown'].map((type) => {
        document.addEventListener(type, handleLogout)
      })
    }

    // let interval = null;
    // if(isTruthy(this.data.get('participant-logged-in'))){
    //   interval = setInterval(() => {
    //     fetch(Env.routes.beat_session_permalink + '?format=json', {
    //       method: 'GET',
    //       credentials: 'same-origin',
    //     }).then(response => {
    //       if(response.ok){
    //         response.json().then((json) => {
    //           if(json.state === 'expired'){
    //             window.location = Env.routes.login_permalink
    //           }
    //
    //         })
    //       }
    //     })
    //   }, 5000);
    // }
  }

  handleSessionClaim(){
    if('app' === this.data.get('realm')){
      if(isTruthy(this.data.get('participant-logged-in')) && isFalsy(this.data.get('session-claimed'))){
        let isSessionClaimed = false;

        const claimSession = () => {
          if(!isSessionClaimed){
            axios({
              method: 'post',
              url: Env.routes.claim_session_permalink,
              data: qs.stringify({
                authenticity_token: document.querySelector('meta[name=csrf-token]').getAttribute('content')
              }),
              headers: {'accept': 'application/javascript'}
            }).then(response => {
              if(200 === response.status){
                isSessionClaimed = true
              }
            })
          }
        }

        if (window.self === window.top) {
          document.addEventListener('click', claimSession);
          document.addEventListener('scroll', claimSession);
        }
      }
    }
  }

  handleMasonry(){
    let lib = null;
    Array.from(document.querySelectorAll('[data-masonry]')).forEach((el) => {
      el.classList.add('hidden')
      if(!lib){ lib = require('macy') }
      const args = JSON.parse(el.dataset.masonry);
      const options = Object.assign({
        container: el,
        trueOrder: false,
        columns: 3,
        breakAt: { 1024: 2, 768: 1 }
      }, args)

      setTimeout(() => {
        var instance = lib(options);
        instance.recalculate()
        el.classList.remove('hidden')
      }, 500)

    })
  }

  _show(element){
    element.removeAttribute('aria-hidden')
    element.style.transition = 'all 150ms ease-in-out'
    // element.style.opacity = 0
    element.classList.remove('hidden')
    if(element.style.display === 'none'){
      element.style.display = ''
    }
    // element.style.opacity = 1
  }

  _hide(element){
    element.setAttribute('aria-hidden', true)
    element.style.transition = 'all 150ms ease-in-out'
    // element.style.opacity = 0
    setTimeout(() => { element.classList.add('hidden') }, 150)
  }

}
