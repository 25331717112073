import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default class extends Controller {

  static values = {
    whitelist: Array
  }

  connect() {

    const tagify = new Tagify(this.element, {
      whitelist: this.whitelistValue,
      dropdown: {
        enabled       : 0,              // show the dropdown immediately on focus
        maxItems      : 5,
        closeOnSelect : true,          // keep the dropdown open after selecting a suggestion
        highlightFirst: true
      },
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    })
  }

}
