import { Controller } from "@hotwired/stimulus"
import { deepMerge } from '@javascript/javascripts/utils'
import lottie from 'lottie-web-light'
// import json from '@vendor/javascripts/lotties/check_green.json'

export default class extends Controller {

  static values = { name: String, options: Object }
  static targets = []

  connect() {
    const speed = this.optionsValue.speed || 1
    delete this.optionsValue.speed

    import(`@vendor/javascripts/lotties/${this.nameValue}.json`).then(json => {
      const defaults = {
        container: this.element,
        animationData: json.default,
        renderer: 'svg',
        loop: false,
        autoplay: true
      }
      const instance = lottie.loadAnimation(deepMerge(defaults, this.optionsValue));
      instance.setSpeed(speed)
    })

  }

}
