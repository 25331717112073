import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input', 'placeholder']

  initialize() {
    this.options = this.data.get('options') ? JSON.parse(this.data.get('options')) : {}
    const inputs = this.inputTargets
    const placeholder = this.placeholderTarget


    Array.from(inputs).forEach((input, index) => {

      this.filterInput(input, function(value) { return /^\d*$/.test(value) && (value === '' || parseInt(value) <= 9) })

      input.addEventListener('input', () => {
        if(input.value.length > 0) {
          if (index < inputs.length - 1) {
            inputs[index + 1].focus();
          } else if ( index === inputs.length - 1 ) {
            placeholder.value = inputs.map(input => input.value).join('')
            Array.from(inputs).map(input => input.setAttribute('disabled', 'disabled'))
            placeholder.form.submit();
          }
        }
      })

      input.addEventListener('keydown', (e) => {
        const key = e.which || e.keyCode || 0;
        if (key === 8) {
          this.value = '';
          if((index - 1) < 0) { return }
          else {
            inputs[index - 1].focus();
          }
        }
      });

    })
    inputs[0].focus()

    const handlePaste = (e) => {
      e.preventDefault()

      const paste = e.clipboardData.getData('text').replace(/\D/g,'');
      inputs.forEach(
          (input, i) => {
            input.value = paste[i] || ''
          }
      )
      placeholder.value = inputs.map(input => input.value).join('')
      Array.from(inputs).map(input => input.setAttribute('disabled', 'disabled'))
      placeholder.form.submit()
    }
    inputs[0].addEventListener('paste', handlePaste);
  }

  filterInput(input, filter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
      input.addEventListener(event, function() {
        if (filter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    });
  }

}
