import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { progression: Number }
  static targets = ['wrapper', 'bar']

  connect() {
    const initialValue = this.barTarget.getAttribute('aria-valuenow')
    if (initialValue != "0") {
      this.barTarget.style.width = initialValue + '%';
    }
  }
}
