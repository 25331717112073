import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['placeholder']

  connect(){
    this.shortcodes = {}
    if(this.hasPlaceholderTarget){
      this._fetchShortcodes(this._render.bind(this))
    }
  }

  _fetchShortcodes(callback){
    fetch(Env.routes.fetch_shortcodes_permalink, {
      method: 'GET',
      credentials: 'same-origin',
    }).then(response => { response.ok ? response.json().then(callback) : this._onFetchError(response) })
  }

  _onFetchError(response){
    this.placeholderTargets.map(placeholder => {
      placeholder.innerHTML = placeholder.innerHTML.replace(/\[\[shortcode\:([a-z0-9]+)\]\]/img, (matches, code) => {
        return ''
      })
    })
  }

  _render(data){
    this.shortcodes = data
    // console.log(this.shortcodes)
    this.placeholderTargets.map(placeholder => {
      placeholder.innerHTML = placeholder.innerHTML.replace(/\[\[shortcode\:([a-z0-9]+)\]\]/img, (matches, code) => {
        return this.shortcodes[code] || `[[shortcode:${code} ⚠️ not found ⚠️]]`
      })
    })
  }
}
