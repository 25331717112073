import { Controller } from "@hotwired/stimulus"
import { tns } from 'tiny-slider/src/tiny-slider.js'

export default class extends Controller {

  static targets = ['wrapper']

  connect() {
    console.log('DEPRECATED in favor of carousel_component!')
    const options = this.data.get('options') ? JSON.parse(this.data.get('options')) : {}
    const defaults = {
      container: this.wrapperTarget,
      speed: 200,
      nav: false,
      loop: false,
      items: 1,
      nonce: 'oVyNsBBOgnKX4o3kiJDiAQ=='
    }
    this.carousel = tns(Object.assign(defaults, options))
  }

}
