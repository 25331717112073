import { Controller } from "@hotwired/stimulus"
import { tns } from 'tiny-slider/src/tiny-slider.js'
import { deepMerge } from '@javascript/javascripts/utils.js'

export default class extends Controller {

  static targets = []

  connect() {
  }

}
